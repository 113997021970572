import React, { useEffect, useState } from "react";
import {
  Image,
  Button,
  Descriptions,
  Divider,
  Tag,
  Tabs,
  Select,
  Table,
  Form,
  Input,
} from "antd";
import { getDataDoc, updateDocDatabase } from "../database";
import dayjs from "dayjs";
import { openErrorNotification } from "./Notifications/errorNotification";
import { query, collection, getDocs, where } from "firebase/firestore";
import { DatePicker, Space } from "antd";
import { db } from "../database/firebase";
import ModalTransfer from "./ModalTransfer";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signOut,
  updateProfile,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";

function generateSixDigitCode() {
  return Math.floor(100000 + Math.random() * 900000);
}
function EditElev({ elevData, setMode, clase, colors, setElevData, id }) {
  const [numeDeFamilie, setNumeDeFamilie] = useState();
  const [prenume, setPrenume] = useState();
  const [adresaEmail, setAdresaEmail] = useState();
  const onlyWidth = useWindowWidth();
  const [clasa, setClasa] = useState();
  const [numarMatricol, setNumarMatricol] = useState();
  const [open, setOpen] = useState(false);
  const [parintii, setParintii] = useState();
  const [promiss, setPromiss] = useState();
  const [numarTelefon, setNumarTelefon] = useState();
  const [profindr, setProfindr] = useState();
  const [insprin, setInsprin] = useState();
  const [scutitMedical, setScutitMedical] = useState("");
  const [dataExpirareMedical, setDataExpirareMedical] = useState("");
  const [ces, setCes] = useState("");

  const [religie, setReligie] = useState("da");
  const [inssec, setInssec] = useState();

  const [cnp, setCnp] = useState("");
  const [loculNasteri, setLoculNasteri] = useState("");
  const [domiciliu, setDomiciliu] = useState("");
  const [details, setDetails] = useState("");
  const [bursa, setBursa] = useState("-");
  const [initiala, setInitiala] = useState("");
  useEffect(() => {
    setNumeDeFamilie(elevData.numeDeFamilie || "");
    setPrenume(elevData.prenume || "");
    setAdresaEmail(elevData.adresaEmail || "");
    setNumarMatricol(elevData.numarMatricol || "");
    setNumarTelefon(elevData.numarTelefon || "");
    setParintii(elevData.parintii || []);
    setClasa(elevData.clasa || "N/A");
    setCnp(elevData.cnp || "");
    setDetails(elevData.details || "");
    setLoculNasteri(elevData.loculNasteri || "");
    setInitiala(elevData.initiala || "");
    setDomiciliu(elevData.domiciliu || "");
    setProfindr(elevData.profindr || "");
    setInsprin(elevData.insprin || "");
    setInssec(elevData.inssec || "");
    setBursa(elevData.bursa || "");
    setScutitMedical(elevData.scutitMedical || "nu");
    setDataExpirareMedical(
      elevData.dataExpirareMedical ? new Date(elevData.dataExpirareMedical) : ""
    );
    setCes(elevData.ces || "nu");
    setReligie(elevData.religie || "da");
  }, [elevData]);

  const onSave = async () => {
    try {
      const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
      };
      if (adresaEmail !== elevData.email) {
        const firebaseConfig = {
          apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
          authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
          projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
          storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
          messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
          appId: process.env.REACT_APP_FIREBASE_APP_ID,
        };
        let Secondary = initializeApp(firebaseConfig, "secondary");
        let auth2 = getAuth(Secondary);
      }
      setElevData({
        ...elevData,
        numeDeFamilie,
        prenume,
        adresaEmail,
        numarMatricol,
        numarTelefon,
        displayName: numeDeFamilie + " " + initiala + " " + prenume,
        parintii,
        clasa,

        cnp,
        details,
        initiala,
        insprin,
        inssec,
        profindr,
        loculNasteri,
        domiciliu,
        bursa,
        scutitMedical,
        dataExpirareMedical: dataExpirareMedical
          ? dataExpirareMedical.getTime()
          : "",
        ces,
        religie,
      });
      await updateDocDatabase("elevi", id, {
        ...elevData,
        numeDeFamilie,
        prenume,
        adresaEmail,
        numarMatricol,
        numarTelefon,
        displayName: numeDeFamilie + " " + initiala + " " + prenume,
        parintii,
        initiala,
        insprin,
        inssec,
        profindr,

        clasa,
        cnp,
        loculNasteri,
        domiciliu,
        bursa,
        scutitMedical,
        dataExpirareMedical: dataExpirareMedical
          ? dataExpirareMedical.getTime()
          : "",
        ces,
        religie,
      });
      await updateDocDatabase("users", id, {
        numeDeFamilie,
        prenume,
        displayName: numeDeFamilie + " " + initiala + " " + prenume,
      });
      const data = await getDataDoc("claseData", elevData.clasa);
      let elevi = (data?.elevi || [])?.filter((el) => el.id !== id);
      const { transferuri } = await getDataDoc("elevi", id);
      console.log(transferuri);
      await updateDocDatabase("claseData", elevData.clasa, {
        elevi: [
          ...elevi,
          {
            id,
            numeDeFamilie,
            prenume,
            adresaEmail,
            parintii,
            initiala,
            scutitMedical,
            dataExpirareMedical: dataExpirareMedical
              ? dataExpirareMedical.getTime()
              : "",
            ces,
            religie,
            transferuri: transferuri || [],
          },
        ],
      });

      const note = await getDataDoc("catalog", id);
      const docs = await getDataDoc("eleviDocumente", id);
      if (clasa !== elevData.clasa) {
        console.log({ gradesFrozen: note, docsFrozen: docs });
        await updateDocDatabase("claseData", elevData.clasa, {
          elevi: [
            ...elevi.filter((el) => el.id !== id),
            {
              id,
              numeDeFamilie,
              prenume,
              adresaEmail,
              parintii,
              initiala,
              scutitMedical,
              dataExpirareMedical: dataExpirareMedical
                ? dataExpirareMedical.getTime()
                : "",
              ces,
              religie,
              transferuri,
              mutat: true,
              gradesFrozen: note?.note || [],
              docsFrozen: docs || [],
            },
          ],
        });
        const data = await getDataDoc("claseData", clasa);
        let newelevi = data?.elevi || [];
        await updateDocDatabase("claseData", clasa, {
          elevi: [
            ...newelevi,
            { id, numeDeFamilie, prenume, adresaEmail, parintii, initiala },
          ],
        });
      }
      for await (let email of parintii) {
        let pass = generateSixDigitCode();

        let Secondary = initializeApp(firebaseConfig, "secondary");
        let auth2 = getAuth(Secondary);
        try {
          await createUserWithEmailAndPassword(auth2, email, pass).then(
            async (userCredentials) => {
              const user = userCredentials.user;
              await updateDocDatabase("mail", "parinte" + pass + Date.now(), {
                to: [email],
                message: {
                  subject:
                    "Ai fost inregistrat ca abonat al elevului " +
                    numeDeFamilie +
                    " " +
                    prenume,

                  text:
                    "Intra pe " +
                    process.env.REACT_APP_LINK +
                    " logheaza-te cu " +
                    email +
                    " si parola " +
                    pass +
                    " ." +
                    " Nu uita ca vei fi obligat sa schimbi parola.",
                },
              });
              await updateProfile(user, { emailVerified: true });
              signOut(auth2);
              await updateDocDatabase("users", user?.uid, {
                reset: true,
                type: "parinte",
                uid: user?.uid,
                emailParinte: email,
                copii: [
                  {
                    adresaEmail,
                    idElev: id,
                    prenume,
                    displayName: numeDeFamilie + " " + initiala + " " + prenume,
                    numeDeFamilie,
                  },
                ],
              });
            }
          );
        } catch (err) {
          const q = query(
            collection(db, "users"),
            where("emailParinte", "==", email)
          );
          let data = undefined;

          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            data = doc.data();
          });
          if (data === undefined) {
            // openErrorNotification("nu am gasit cont parinte");
          }
          if (data?.uid)
            await updateDocDatabase("users", data.uid, {
              copii: [
                ...(data.copii || []).filter((e) => e.idElev !== id),
                {
                  adresaEmail,
                  idElev: id,
                  prenume,
                  displayName: numeDeFamilie + " " + initiala + " " + prenume,
                  scutitMedical,
                  dataExpirareMedical: dataExpirareMedical
                    ? dataExpirareMedical.getTime()
                    : "",
                  ces,
                  religie,
                  numeDeFamilie,
                },
              ],
            });
        }
      }
    } catch (e) {
      openErrorNotification(e.message);
    }
  };
  console.log(open);
  return (
    <>
      <ModalTransfer
        open={open}
        setOpen={setOpen}
        elevData={elevData}
        promiss={promiss}
        id={id}
        setClasa={setClasa}
      />
      <Descriptions
        bordered
        layout={onlyWidth < 900 ? "vertical" : "horizontal"}
        title={
          (elevData.numeDeFamilie + " " + initiala + " ",
          elevData.prenume +
            " - " +
            clase?.find((cls) => cls.id === elevData.clasa)?.anClasa +
            " " +
            clase?.find((cls) => cls.id === elevData.clasa)?.identificator)
        }
        extra={[
          <Button
            onClick={() => {
              setMode("view");
            }}
            style={{ marginRight: "10px", color: "red" }}
          >
            Cancel
          </Button>,

          <Button
            type="primary"
            onClick={() => {
              onSave();
              setMode("view");
            }}
          >
            Save
          </Button>,
        ]}
      >
        <Descriptions.Item label="Nume">
          <Input
            value={numeDeFamilie}
            onChange={(e) => {
              setNumeDeFamilie(e.target.value);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Prenume">
          {" "}
          <Input
            value={prenume}
            onChange={(e) => {
              setPrenume(e.target.value);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Initiala">
          {" "}
          <Input
            value={initiala}
            onChange={(e) => {
              setInitiala(e.target.value);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Clase">
          <Select
            value={clasa}
            onChange={(e, value) => {
              setPromiss(value.value);
              setOpen(true);
            }}
            options={[
              ...clase.map((cls) => {
                return {
                  label: cls.anClasa + cls.identificator,
                  value: cls.id,
                };
              }),

              { label: "N/A", value: "faraclasa" },
            ]}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Nr. Matricol">
          <Input
            value={numarMatricol}
            onChange={(e) => {
              setNumarMatricol(e.target.value);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Nr. Telefon">
          <Input
            value={numarTelefon}
            onChange={(e) => {
              setNumarTelefon(e.target.value);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="CNP">
          <Input
            value={cnp}
            onChange={(e) => {
              setCnp(e.target.value);
            }}
          />
        </Descriptions.Item>
        {process.env.REACT_APP_NAME === "LMTJ" && (
          <Descriptions.Item label="Prof. Indr">
            <Input
              value={profindr}
              onChange={(e) => {
                setProfindr(e.target.value);
              }}
            />
          </Descriptions.Item>
        )}
        {process.env.REACT_APP_NAME === "LMTJ" && (
          <Descriptions.Item label="Ins. Prin.">
            <Input
              value={insprin}
              onChange={(e) => {
                setInsprin(e.target.value);
              }}
            />
          </Descriptions.Item>
        )}
        {process.env.REACT_APP_NAME === "LMTJ" && (
          <Descriptions.Item label="Ins. Sec.">
            <Input
              value={inssec}
              onChange={(e) => {
                setInssec(e.target.value);
              }}
            />
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Locul Nasterii">
          <Input
            value={loculNasteri}
            onChange={(e) => {
              setLoculNasteri(e.target.value);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Domiciliu">
          <Input
            value={domiciliu}
            onChange={(e) => {
              setDomiciliu(e.target.value);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Scutit medical">
          <Input
            value={scutitMedical}
            onChange={(e) => {
              setScutitMedical(e.target.value);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Scutit medical">
          <DatePicker
            value={dataExpirareMedical ? dayjs(dataExpirareMedical) : null}
            format={"DD/MM/YYYY"}
            onChange={(e) => {
              setDataExpirareMedical(e.toDate());
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="CES">
          <Select
            value={ces}
            onChange={(e) => {
              setCes(e);
            }}
            options={[
              { label: "NU", value: "nu" },
              { label: "DA", value: "da" },
            ]}
          />
        </Descriptions.Item>

        <Descriptions.Item label="Religie">
          <Input
            value={religie}
            onChange={(e) => {
              setReligie(e.target.value);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Bursa">
          <Select
            value={bursa}
            onChange={(e) => {
              setBursa(e);
            }}
            options={[
              { label: "-", value: "-" },
              { label: "nebursier", value: "nebursier" },
              { label: "merit", value: "merit" },
              { label: "sociala", value: "sociala" },
              { label: "performanta", value: "performanta" },
            ]}
          />
        </Descriptions.Item>

        <Descriptions.Item label="Abonati">
          <Select
            mode="tags"
            value={parintii || []}
            onChange={(e) => {
              setParintii(e);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Detalii">
          <Input
            value={details}
            onChange={(e) => {
              setDetails(e.target.value);
            }}
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

export default EditElev;
