import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Space, Divider, Switch, Popconfirm } from "antd";
import "./Catalog.css";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../database/firebase";
import { Accordion, Icon, Popup, Button as BS } from "semantic-ui-react";
import { arrayUnion } from "firebase/firestore";
import ScutireDisplay from "./ScutireDisplay";
import Docxtemplater from "docxtemplater";
import { motiveazaAbsente } from "../utils/absente";
import { Alert } from "antd";

import PizZip from "pizzip";
import { saveAs } from "file-saver";
import template from "./instiintare parinti 2024 completat.docx";

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";
import { getDataDoc } from "../database";
import { updateDocDatabase } from "../database";
import ModalAddGrade from "./ModalAddGrade";
import { Table as CatalogTabel } from "semantic-ui-react";
import ModalViewGrade from "./ModalViewGrade";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { openSuccesNotification } from "../Components/Notifications/succesNotification";
import {
  calculare_medii,
  calculeaza_medie_materie,
} from "../utils/calculare_medie";
import { exportExcel } from "./excelexport";
import { openErrorNotification } from "./Notifications/errorNotification";
import { maxHeightIcon } from "@progress/kendo-svg-icons";

function Catalog({ classData, setClassData, mode = "edit", permision }) {
  const [eleviData, setEleviData] = useState([]);
  const componentRef = useRef();
  const onlyWidth = useWindowWidth();
  const [open, setOpen] = useState(false);
  const [gradesElevi, setGradesElevi] = useState([]);
  const [elevId, setElevId] = useState();
  const [elevId2, setElevId2] = useState();
  const [teza, setTeza] = useState();
  const [materieId, setMaterieId] = useState();
  const print = useRef();
  const [nota, setNota] = useState();
  const [inchideMediiAutomat, setInchideMediiAutomat] = useState(false);
  const [allData, setAllData] = useState({});
  const profesori = useSelector((state) => state.profesori);
  const settings = useSelector((state) => state.settings);
  const Limit = 40;
  const [deleted, setDeleted] = useState("");
  const [motivStergereMedie, setMotivStergereMedie] = useState("");
  const [notePrint, setNotePrint] = useState([]);
  const [author, setAuthor] = useState("");
  const navigate = useNavigate();
  const [comentariu, setComentariu] = useState();
  const [edit, setEdit] = useState(false);

  const [faraNote, setFaraNote] = useState(false);
  const windowSize = useRef(window.innerWidth);
  const [data, setData] = useState();
  const user = useSelector((state) => state.user);

  const [tip, setTip] = useState();
  const [entity, setEntity] = useState({});
  const [scutiri, setScutiri] = useState({});
  const [display, setDisplay] = useState(false);

  const [id, setId] = useState("");
  const [profileElevi, setProfileElevi] = useState({});
  const [open2, setOpen2] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const banned = [
    "Teorie-solfegiu-dicteu",
    "Armonie",
    "Etnografie și folclor muzical",
    "Forme muzicale",
    "Muzică vocală tradițională românească",
    "Artă vocală interpretativă",
    "Corepetiție",
    "Instrument principal - Pian",
    "Instrument principal - Vioară",
    "Instrument principal - Chitară",
    "Instrument principal - Clarinet",
    "Instrument principal - Flaut",
    "Instrument principal - Contrabas",
    "Pian complementar",
    "Instrument la alegere - Vioară",
    "Instrument la alegere - Chitară",
    "Instrument la alegere - Clarinet",
    "Instrument la alegere - Flaut",
    "Instrument la alegere - Contrabas",
    "Instrument auxiliar - Vioară",
    "Instrument auxiliar - Chitară",
    "Instrument auxiliar - Clarinet",
    "Instrument auxiliar - Flaut",
    "Ansamblu coral/Ansamblu instrumental",
    "Acompaniament",
    "Ansamblu orchestral/Ansamblu instrumental/coral",
    "Ansamblu folcloric",
    "Muzică de cameră",
  ];

  const pref = [
    "Limba și literatura română",
    "Limba Engleză",
    "Limba Italiană",
    "Matematică",
    "Fizică",
    "Chimie",
    "Biologie",
    "Istorie",
    "Geografie",
    "Logică",
    "Religie",
    "Arte vizuale și abilități practice",
    "Educație fizică",
    "Teorie-solfegiu-dicteu",
    "Tehnologia informației și a comunicațiilor",
    "Etnografie și folclor muzical",
    "Muzică vocală tradițională românească",
    "Instrument la alegere - Vioară",
    "Instrument la alegere - Chitară",
    "Instrument la alegere - Clarinet",
    "Instrument la alegere - Flaut",
    "Instrument la alegere - Contrabas",
    "Corepetiție",
    "Ansamblu folcloric",
    "Istoria Muzicii",
    "Psihologie",
    "Educație Anteprenorială",
    "Economie",
    "Armonie",
    "Forme muzicale",
    "Muzică de cameră",
    "Consiliere și orientare / Purtare",
  ];

  function groupArrayIntoChunks(array, chunkSize = 3) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  async function generateDocument(resume, templatePath) {
    try {
      const response = await fetch(templatePath);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.arrayBuffer();

      if (data.byteLength === 0) {
        throw new Error("Template data is empty!");
      }

      let zip;
      try {
        zip = new PizZip(data);
      } catch (error) {
        throw new Error("Error reading ZIP data: " + error.message);
      }

      let templateDoc;
      try {
        templateDoc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
      } catch (error) {
        throw new Error("Error loading docxtemplater: " + error.message);
      }

      templateDoc.render(resume);

      const base64 = templateDoc.getZip().generate({
        type: "base64",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      return base64;
    } catch (error) {
      console.error("Error generating document: " + error.message);
      return null;
    }
  }

  const styleD = () => {
    if (onlyWidth < 700) return "auto";
    if (onlyWidth < 1000) return "auto auto ";
    if (onlyWidth < 1200) return "auto auto auto ";
    return "auto auto auto  ";
  };

  const fetchData = async () => {
    let newArray = [];
    let scutiriElevi = {};
    let note = {};
    let NotePrint = [];

    for (let elev of classData?.elevi || []) {
      const docs = await getDataDoc("eleviDocumente", elev.id);

      scutiriElevi[elev.id] = docs?.docsElev.filter(
        (doc) => doc.tip === "scutire" || doc.tip === "bilet"
      );
    }

    for await (let elev of classData?.elevi || []) {
      let obj = {};
      const notes = await getDataDoc("catalog", elev.id);
      //   if (elev.mutat === true) {
      //     note[elev.id] = {
      //       note: elev.gradesFrozen,
      //       absente: motiveazaAbsente(
      //         elev.gradesFrozen,
      //         elev?.docsFrozen?.docsElev
      //       ),
      //     };
      //   } else
      note[elev.id] = {
        note: notes?.note,
        absente: motiveazaAbsente(notes?.note, scutiriElevi[elev.id]),
      };
      let dataE = await getDataDoc("elevi", elev.id);
      newArray.push({
        ...elev,
        ...dataE,
        key: elev.id,
        nume: elev.numeDeFamilie + " " + elev.initiala + " " + elev.prenume,
      });
    }

    setNotePrint(NotePrint);

    setScutiri(scutiriElevi);
    setEleviData(newArray);
    setGradesElevi(note);
  };
  const decideNumber = () => {
    if (onlyWidth < 700) return 1;
    if (onlyWidth < 900) return 2;
    if (onlyWidth < 1500) return 3;

    return 4;
  };
  const groupArray = (array) => {
    let newArray = [[]];
    let index = 0;
    (array || []).forEach((e) => {
      if (newArray[index].length < decideNumber()) newArray[index].push(e);
      else newArray[++index] = [e];
    });
    return newArray;
  };
  const formatDate = (today) => {
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "." + mm;
  };
  useEffect(() => {
    fetchData();
  }, [classData]);
  useEffect(() => {
    let array = [];
    for (let elev of classData?.elevi || []) {
      const unsub = onSnapshot(doc(db, "catalog", elev.id), (doc) => {
        fetchData();
      });
      const unsub2 = onSnapshot(doc(db, "eleviDocumente", elev.id), (doc) => {
        fetchData();
      });
      array.push(unsub);
      array.push(unsub2);
    }
  }, []);

  const fetchProfiles = async () => {
    let profileElevi = {};
    for await (let elev of classData?.elevi || []) {
      const profilElev = await getDataDoc("elevi", elev.id);
      profileElevi[elev.id] = profilElev;
    }
    setProfileElevi(profileElevi);
  };
  useEffect(() => {
    if (display === true) {
      fetchProfiles();
    }
  }, [display]);

  const materii = useSelector((state) => state.materii);
  const handlePrint = useReactToPrint({
    content: () => print.current,
  });

  console.log({ eleviData });
  return (
    <>
      <h3>Catalogul este gata</h3>
      <Button
        onClick={() => {
          handlePrint();
        }}
      >
        Print
      </Button>
      <div
        className="catalog-export"
        //   style={{ display: "none" }}
      >
        <div style={{ width: "420mm" }} ref={print} className="catalog-export">
          <div style={{}}>
            <br /> <br />
            <h1 style={{ textAlign: "center" }}>
              Catalogul clasei{" "}
              {classData.anClasa + " " + classData.identificator}
            </h1>
            <h4 style={{ textAlign: "center" }}>
              Instituție școlară:{settings.numeInstitutie}
              <br />
              Anul școlar 2023-2024
              <br />
              Diriginte/Învățător:{" "}
              {profesori.find((p) => p.id === classData.diriginte)
                ?.numeDeFamilie +
                " " +
                profesori.find((p) => p.id === classData.diriginte)?.prenume}
              <br />
              <br /> Export realizat din catalogul digital, găzduit de PFA
              Dobricean Ioan Dorian. <br />
              CUI: 46952301
            </h4>
            <h5 style={{ paddingLeft: "50px" }}>Materii înscrise în catalog</h5>
            <table border="1" style={{ width: "90%", marginLeft: "5%" }}>
              <thead>
                <tr>
                  <th>Materie</th>
                  <th>Profesori</th>
                </tr>
              </thead>
              <tbody>
                {classData?.materii?.map((m) => {
                  return (
                    <tr
                      style={{
                        pageBreakInside: "avoid",
                        pageBreakAfter: "auto",
                        textAlign: "center",
                      }}
                    >
                      <td>
                        <p style={{ paddingLeft: "10px" }}>
                          {
                            materii?.find((ma) => ma.id === m.materie)
                              ?.numeMaterie
                          }
                        </p>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "auto auto  ",
                            paddingLeft: "10px",
                            pageBreakInside: "avoid",
                            pageBreakAfter: "auto",
                            textAlign: "center",
                          }}
                        >
                          {m?.profesori?.map((idp) => (
                            <p>
                              {profesori?.find((p) => p.id === idp)
                                ?.numeDeFamilie +
                                " " +
                                profesori?.find((p) => p.id === idp)?.prenume}
                            </p>
                          ))}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <br />
            <br />
          </div>
          {groupArrayIntoChunks(
            eleviData.sort((a, b) => {
              if (a.mutat || b.mutat)
                return a?.nume?.localeCompare(b.nume, "ro");
              if (a.transferuri && b.transferuri)
                return (
                  a.transferuri[a.transferuri.length - 1] -
                  b.transferuri[b.transferuri.length - 1]
                );
              else if (a.transferuri) return 1;
              else if (b.transferuri) return -1;
              return a?.nume?.localeCompare(b.nume, "ro");
            })
          ).map((elevi) => {
            return (
              <>
                <CatalogTabel celled style={{ pageBreakAfter: "always" }}>
                  <CatalogTabel.Header>
                    <CatalogTabel.Row>
                      <CatalogTabel.HeaderCell style={{ textAlign: "center" }}>
                        Elevii
                      </CatalogTabel.HeaderCell>
                      <CatalogTabel.HeaderCell style={{ textAlign: "end" }}>
                        DISCIPLINE ** DEE
                      </CatalogTabel.HeaderCell>
                    </CatalogTabel.Row>
                  </CatalogTabel.Header>

                  <CatalogTabel.Body>
                    {elevi.map((e, index) => {
                      function formatMedia(media, zecimal = true) {
                        if (
                          typeof media !== "number" ||
                          media < 0 ||
                          media > 10
                        ) {
                          return "Media trebuie să fie un număr între 0 și 10.";
                        }

                        const numereInLitere = [
                          "zero",
                          "unu",
                          "doi",
                          "trei",
                          "patru",
                          "cinci",
                          "șase",
                          "șapte",
                          "opt",
                          "nouă",
                          "zece",
                        ];

                        const intPart = Math.floor(media); // Partea întreagă
                        const decimalPart = Math.round((media - intPart) * 100); // Partea zecimală ca procent

                        const intPartInLitere = numereInLitere[intPart];
                        if (zecimal === true)
                          return `${intPartInLitere} și ${decimalPart}%`;
                        else return `${intPartInLitere}`;
                      }

                      return (
                        <CatalogTabel.Row
                          style={{
                            pageBreakInside: "avoid",
                          }}
                        >
                          <CatalogTabel.Cell
                            style={{
                              position: "relative",
                              whiteSpace: "break-spaces",

                              textAlign: "center",
                              width: "200px",
                            }}
                            className="detalii"
                          >
                            {" "}
                            <p>
                              Trecut în registrul matricol vol:{" "}
                              {e.numarMatricol.split("/")?.[1]}, p.
                              {e.numarMatricol.split("/")?.[2]}{" "}
                            </p>
                            <p>
                              {index + 1}. {e.nume}
                            </p>
                            <p>CNP: {e.cnp}</p>
                            <p>
                              Nr. matricol: {e.numarMatricol.split("/")?.[0]}
                            </p>
                            {e?.retras === true ? " - retras" : ""}
                            {e?.ces === "da" && (
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "purple",
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                Cerințe educaționale speciale
                              </p>
                            )}
                            <p>SITUAȚIA ȘCOLARĂ din sesiunile:</p>
                            <p>iunie-iulie:</p>
                            <p>august-septembrie:</p>
                            <p>
                              Media generală:{" "}
                              {calculare_medii(
                                gradesElevi[e.id]?.note || [],
                                materii,
                                scutiri[e.id]
                              ) + " "}
                              (
                              {formatMedia(
                                parseFloat(
                                  calculare_medii(
                                    gradesElevi[e.id]?.note || [],
                                    materii,
                                    scutiri[e.id]
                                  )
                                )
                              )}
                              )
                            </p>
                            <p>Mentiuni:</p>
                            {e?.transferuri?.length > 0 &&
                              e.transferuri.map((t) => {
                                return (
                                  <p>
                                    Transferat în
                                    {" " +
                                      formatDate(new Date(t.dataTransfer))}{" "}
                                    <br />
                                    {t.details}
                                  </p>
                                );
                              })}
                          </CatalogTabel.Cell>
                          <CatalogTabel.Cell>
                            {[
                              classData?.materii?.slice(
                                0,
                                Math.floor(
                                  (classData?.materii?.length || 0) / 2
                                )
                              ),
                            ].map((group) => {
                              return (
                                <CatalogTabel
                                  attached
                                  celled
                                  fixed
                                  style={{ pageBreakInside: "avoid" }}
                                >
                                  <CatalogTabel.Header>
                                    {e === elevi[0] && (
                                      <CatalogTabel.Row>
                                        {group.map((m) => {
                                          return (
                                            <CatalogTabel.HeaderCell
                                              style={{ textAlign: "center" }}
                                            >
                                              {
                                                materii?.find(
                                                  (ma) => ma.id === m.materie
                                                )?.numeMaterie
                                              }
                                            </CatalogTabel.HeaderCell>
                                          );
                                        })}
                                      </CatalogTabel.Row>
                                    )}
                                  </CatalogTabel.Header>
                                  <CatalogTabel.Body>
                                    <CatalogTabel.Row>
                                      {group.map((m) => {
                                        let medie = calculeaza_medie_materie(
                                          gradesElevi[e.id]?.note,
                                          materii?.find(
                                            (n) => n.id == m.materie
                                          ),
                                          scutiri[e.id]
                                        );
                                        let { corigenta, inchis } = medie;

                                        return (
                                          <CatalogTabel.Cell
                                            style={{ height: "300px" }}
                                          >
                                            <CatalogTabel attached celled fixed>
                                              <CatalogTabel.Header>
                                                {e === elevi[0] && (
                                                  <CatalogTabel.Row>
                                                    <CatalogTabel.HeaderCell
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      Absente
                                                    </CatalogTabel.HeaderCell>

                                                    <CatalogTabel.HeaderCell
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      Note
                                                    </CatalogTabel.HeaderCell>
                                                  </CatalogTabel.Row>
                                                )}
                                              </CatalogTabel.Header>
                                              <CatalogTabel.Body>
                                                <CatalogTabel.Row
                                                  style={{
                                                    borderBottom:
                                                      "1px solid black",
                                                    backgroundColor: "unset",
                                                    //activeaza doar daca vrei sa arati ca nu sunt suficiente note
                                                    // (
                                                    //   gradesElevi[e.id]?.note ||
                                                    //   []
                                                    // ).filter(
                                                    //   (n) =>
                                                    //     n.tip === "nota" &&
                                                    //     n.materieId === m.materie
                                                    // ).length > 0 &&
                                                    // (
                                                    //   gradesElevi[e.id]?.note ||
                                                    //   []
                                                    // ).filter(
                                                    //   (n) =>
                                                    //     n.tip === "nota" &&
                                                    //     n.materieId === m.materie
                                                    // ).length < 4 &&
                                                    // (
                                                    //   gradesElevi[e.id]?.note ||
                                                    //   []
                                                    // ).filter(
                                                    //   (n) =>
                                                    //     n.tip === "corigenta" &&
                                                    //     n.materieId === m.materie
                                                    // ).length === 0
                                                    //   ? "#eb9371"
                                                    //   : "unset",
                                                  }}
                                                >
                                                  <CatalogTabel.Cell>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column", // Elemente unul sub altul
                                                        justifyContent:
                                                          "center", // Centrare verticală
                                                        alignItems: "center", // Centrare orizontală
                                                        margin: "auto", // Centrare generală în container
                                                        textAlign: "center", // Text centrat
                                                      }}
                                                    >
                                                      {gradesElevi[
                                                        e.id
                                                      ]?.absente.absente_dupa_motivari
                                                        .filter(
                                                          (abs) =>
                                                            abs.materieId ===
                                                            m.materie
                                                        )
                                                        ?.map((nota) => {
                                                          let date = new Date(
                                                            nota.date
                                                          );
                                                          return nota.motivat ===
                                                            false ? (
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  nota?.delete ===
                                                                  "waiting"
                                                                    ? "grey"
                                                                    : "red",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              {formatDate(date)}
                                                            </p>
                                                          ) : (
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                                textAlign:
                                                                  "center",
                                                                border:
                                                                  nota?.scutire
                                                                    ?.tip ===
                                                                  "bilet"
                                                                    ? "2.5px solid #a3eb07"
                                                                    : "1px solid green",
                                                                textAlign:
                                                                  "center",

                                                                borderStyle:
                                                                  nota?.scutire
                                                                    ?.tip ===
                                                                  "bilet"
                                                                    ? "dotted"
                                                                    : "solid",

                                                                wordBreak:
                                                                  "keep-all",
                                                                borderRadius:
                                                                  "2px",
                                                                maxWidth:
                                                                  "40px",
                                                                color:
                                                                  nota?.delete ===
                                                                  "waiting"
                                                                    ? "grey"
                                                                    : "green",
                                                              }}
                                                            >
                                                              {formatDate(date)}
                                                            </p>
                                                          );
                                                        })}
                                                    </div>
                                                  </CatalogTabel.Cell>

                                                  <CatalogTabel.Cell>
                                                    <div
                                                      style={{
                                                        display: "block",
                                                        gridTemplateColumns:
                                                          "auto",
                                                      }}
                                                    >
                                                      {(
                                                        gradesElevi[e.id]
                                                          ?.note || []
                                                      )
                                                        .filter(
                                                          (n) =>
                                                            n.tip === "nota" &&
                                                            n.materieId ===
                                                              m.materie
                                                        )
                                                        .map((nota) => (
                                                          <p
                                                            style={{
                                                              fontSize: "18px",
                                                              textAlign:
                                                                "center",
                                                              color:
                                                                nota?.delete ===
                                                                "waiting"
                                                                  ? "grey"
                                                                  : "#1c90ff",
                                                            }}
                                                            onClick={() => {
                                                              setAllData(nota);
                                                              setNota(
                                                                nota.nota
                                                              );
                                                              setMaterieId(
                                                                nota.materieId
                                                              );
                                                              setTip(nota.tip);
                                                              setDeleted(
                                                                nota?.delete
                                                              );
                                                              setAuthor(
                                                                nota.author ||
                                                                  ""
                                                              );
                                                              setComentariu(
                                                                nota.comentariu
                                                              );
                                                              setId(nota.id);
                                                              setData(
                                                                new Date(
                                                                  nota.date
                                                                )
                                                              );
                                                              setElevId2(e.id);
                                                              setOpen2(true);
                                                            }}
                                                          >
                                                            {nota.nota}
                                                          </p>
                                                        ))}
                                                    </div>
                                                    {(
                                                      gradesElevi[e.id]?.note ||
                                                      []
                                                    ).find(
                                                      (n) =>
                                                        n.materieId ===
                                                          m.materie &&
                                                        n.tip === "examen_final"
                                                    ) && (
                                                      <p
                                                        style={{
                                                          textAlign: "center",
                                                          color:
                                                            (
                                                              gradesElevi[e.id]
                                                                ?.note || []
                                                            ).find(
                                                              (n) =>
                                                                n.materieId ===
                                                                  m.materie &&
                                                                n.tip ===
                                                                  "examen_final"
                                                            )?.delete ===
                                                            "waiting"
                                                              ? "grey"
                                                              : "purple",
                                                          textAlign: "center",
                                                        }}
                                                        onClick={() => {
                                                          const nota = (
                                                            gradesElevi[e.id]
                                                              ?.note || []
                                                          ).find(
                                                            (n) =>
                                                              n.materieId ===
                                                                m.materie &&
                                                              n.tip ===
                                                                "examen_final"
                                                          );
                                                          setAllData(nota);
                                                          setNota(
                                                            nota.examen_final
                                                          );
                                                          setMaterieId(
                                                            nota.materieId
                                                          );
                                                          setTip(nota.tip);
                                                          setDeleted(
                                                            nota.delete
                                                          );
                                                          setAuthor(
                                                            nota.author || ""
                                                          );
                                                          setComentariu(
                                                            nota.comentariu
                                                          );
                                                          setId(nota.id);
                                                          setData(
                                                            new Date(nota.date)
                                                          );
                                                          setElevId2(e.id);
                                                          setOpen2(true);
                                                        }}
                                                      >
                                                        Ex.Final:
                                                        {
                                                          (
                                                            gradesElevi[e.id]
                                                              ?.note || []
                                                          ).find(
                                                            (n) =>
                                                              n.materieId ===
                                                                m.materie &&
                                                              n.tip ===
                                                                "examen_final"
                                                          ).examen_final
                                                        }
                                                      </p>
                                                    )}
                                                    {corigenta && (
                                                      <p
                                                        style={{
                                                          textAlign: "center",
                                                          fontSize: "10px",
                                                          color: "delete =",
                                                          paddingTop: "5px",
                                                          borderTop:
                                                            "1px solid black",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Media intiala:{" "}
                                                        {medie.medieInitiala}
                                                      </p>
                                                    )}
                                                    {corigenta && (
                                                      <p
                                                        style={{
                                                          fontSize: "10px",
                                                          textAlign: "center",
                                                          paddingTop: "5px",
                                                          borderTop:
                                                            "1px solid black",
                                                          color:
                                                            nota?.delete ===
                                                            "waiting"
                                                              ? "grey"
                                                              : "purple",
                                                        }}
                                                        onClick={() => {
                                                          setAllData(nota);
                                                          setNota(
                                                            corigenta?.corigenta
                                                          );
                                                          setMaterieId(
                                                            corigenta?.materieId
                                                          );
                                                          setTip(
                                                            corigenta?.tip
                                                          );
                                                          setDeleted(
                                                            nota.delete
                                                          );
                                                          setAuthor(
                                                            corigenta?.author ||
                                                              ""
                                                          );
                                                          setComentariu(
                                                            corigenta?.comentariu
                                                          );
                                                          setId(corigenta?.id);
                                                          setData(
                                                            new Date(
                                                              corigenta?.date
                                                            )
                                                          );
                                                          setElevId2(e.id);
                                                          setOpen2(true);
                                                        }}
                                                      >
                                                        {medie.noteInsuficiente
                                                          ? "Medie neîncheiat:"
                                                          : "Media corigenta:"}{" "}
                                                        {corigenta.corigenta}
                                                      </p>
                                                    )}
                                                    <Space>
                                                      {materii
                                                        .find(
                                                          (ma) =>
                                                            ma.id === m?.materie
                                                        )
                                                        ?.numeMaterie?.includes(
                                                          "Educație fizică"
                                                        ) &&
                                                        e?.scutitMedical
                                                          ?.length > 0 &&
                                                        e?.scutitMedical
                                                          ?.length > 0 &&
                                                        (e?.dataExpirareMedical
                                                          ? new Date() <=
                                                            new Date(
                                                              e.dataExpirareMedical
                                                            )
                                                          : true) &&
                                                        e.scutitMedical !==
                                                          "nu" && (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                              fontSize: "12px",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            -scutit medical
                                                            conform{" "}
                                                            {e.scutitMedical}-
                                                          </p>
                                                        )}
                                                      {materii.find(
                                                        (ma) =>
                                                          ma.id === m?.materie
                                                      )?.numeMaterie ===
                                                        "Religie" &&
                                                        e?.religie?.length >
                                                          0 &&
                                                        e?.religie !== "da" && (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                              fontSize: "12px",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            -retras religie
                                                            conform {e?.religie}
                                                            -
                                                          </p>
                                                        )}
                                                    </Space>
                                                  </CatalogTabel.Cell>
                                                </CatalogTabel.Row>
                                              </CatalogTabel.Body>
                                            </CatalogTabel>
                                          </CatalogTabel.Cell>
                                        );
                                      })}
                                    </CatalogTabel.Row>
                                    <CatalogTabel.Row>
                                      {group.map((m) => {
                                        let medie = calculeaza_medie_materie(
                                          gradesElevi[e.id]?.note,
                                          materii?.find(
                                            (n) => n.id == m.materie
                                          ),
                                          scutiri[e.id]
                                        );
                                        let { corigenta, inchis } = medie;
                                        return (
                                          <CatalogTabel.Cell
                                            style={{
                                              height: "20px",

                                              textAlign: "center",
                                              width: "120%",
                                            }}
                                          >
                                            Medie:
                                            {inchis ? (
                                              <>
                                                {edit !== inchis?.id ? (
                                                  <>
                                                    {formatMedia(
                                                      inchis.inchidere,
                                                      false
                                                    )}
                                                  </>
                                                ) : (
                                                  <div></div>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {corigenta ? (
                                                  <>
                                                    {" "}
                                                    {corigenta.corigenta +
                                                      "(" +
                                                      formatMedia(
                                                        corigenta.corigenta,
                                                        false
                                                      ) +
                                                      ")"}
                                                  </>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    {medie.medie +
                                                      "(" +
                                                      formatMedia(
                                                        medie.medie,
                                                        false
                                                      ) +
                                                      ")"}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </CatalogTabel.Cell>
                                        );
                                      })}
                                    </CatalogTabel.Row>
                                  </CatalogTabel.Body>
                                </CatalogTabel>
                              );
                            })}
                          </CatalogTabel.Cell>
                        </CatalogTabel.Row>
                      );
                    })}
                  </CatalogTabel.Body>
                </CatalogTabel>

                <CatalogTabel celled style={{ pageBreakAfter: "always" }}>
                  <CatalogTabel.Header>
                    <CatalogTabel.Row>
                      <CatalogTabel.HeaderCell
                        style={{ textAlign: "center" }}
                      ></CatalogTabel.HeaderCell>
                      <CatalogTabel.HeaderCell style={{ textAlign: "end" }}>
                        DISCIPLINE ** DEE
                      </CatalogTabel.HeaderCell>
                      <CatalogTabel.HeaderCell style={{ textAlign: "end" }}>
                        Absente
                      </CatalogTabel.HeaderCell>
                    </CatalogTabel.Row>
                  </CatalogTabel.Header>

                  <CatalogTabel.Body>
                    {elevi.map((e, index) => {
                      function formatMedia(media, zecimal = true) {
                        if (
                          typeof media !== "number" ||
                          media < 0 ||
                          media > 10
                        ) {
                          return "Media trebuie să fie un număr între 0 și 10.";
                        }

                        const numereInLitere = [
                          "zero",
                          "unu",
                          "doi",
                          "trei",
                          "patru",
                          "cinci",
                          "șase",
                          "șapte",
                          "opt",
                          "nouă",
                          "zece",
                        ];

                        const intPart = Math.floor(media); // Partea întreagă
                        const decimalPart = Math.round((media - intPart) * 100); // Partea zecimală ca procent

                        const intPartInLitere = numereInLitere[intPart];
                        if (zecimal)
                          return `${intPartInLitere} și ${decimalPart}%`;
                        return `${intPartInLitere}`;
                      }

                      return (
                        <CatalogTabel.Row
                          style={{
                            pageBreakInside: "avoid",
                          }}
                        >
                          <CatalogTabel.Cell
                            style={{
                              position: "relative",
                              whiteSpace: "break-spaces",

                              textAlign: "center",
                              width: "0px",
                            }}
                            className="detalii"
                          ></CatalogTabel.Cell>
                          <CatalogTabel.Cell>
                            {[
                              classData?.materii?.slice(
                                Math.floor(
                                  (classData?.materii?.length || 0) / 2
                                )
                              ),
                            ].map((group) => {
                              return (
                                <CatalogTabel
                                  attached
                                  celled
                                  fixed
                                  style={{ pageBreakInside: "avoid" }}
                                >
                                  <CatalogTabel.Header>
                                    {e === elevi[0] && (
                                      <CatalogTabel.Row>
                                        {group.map((m) => {
                                          return (
                                            <CatalogTabel.HeaderCell
                                              style={{ textAlign: "center" }}
                                            >
                                              {
                                                materii?.find(
                                                  (ma) => ma.id === m.materie
                                                )?.numeMaterie
                                              }
                                            </CatalogTabel.HeaderCell>
                                          );
                                        })}
                                      </CatalogTabel.Row>
                                    )}
                                  </CatalogTabel.Header>
                                  <CatalogTabel.Body>
                                    <CatalogTabel.Row>
                                      {group.map((m) => {
                                        let medie = calculeaza_medie_materie(
                                          gradesElevi[e.id]?.note,
                                          materii?.find(
                                            (n) => n.id == m.materie
                                          ),
                                          scutiri[e.id]
                                        );
                                        let { corigenta, inchis } = medie;

                                        return (
                                          <CatalogTabel.Cell
                                            style={{ height: "300px" }}
                                          >
                                            <CatalogTabel attached celled fixed>
                                              <CatalogTabel.Header>
                                                {e === elevi[0] && (
                                                  <CatalogTabel.Row>
                                                    <CatalogTabel.HeaderCell
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      Absente
                                                    </CatalogTabel.HeaderCell>

                                                    <CatalogTabel.HeaderCell
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      Note
                                                    </CatalogTabel.HeaderCell>
                                                  </CatalogTabel.Row>
                                                )}
                                              </CatalogTabel.Header>
                                              <CatalogTabel.Body>
                                                <CatalogTabel.Row
                                                  style={{
                                                    borderBottom:
                                                      "1px solid black",
                                                    backgroundColor: "unset",
                                                    //activeaza doar daca vrei sa arati ca nu sunt suficiente note
                                                    // (
                                                    //   gradesElevi[e.id]?.note ||
                                                    //   []
                                                    // ).filter(
                                                    //   (n) =>
                                                    //     n.tip === "nota" &&
                                                    //     n.materieId === m.materie
                                                    // ).length > 0 &&
                                                    // (
                                                    //   gradesElevi[e.id]?.note ||
                                                    //   []
                                                    // ).filter(
                                                    //   (n) =>
                                                    //     n.tip === "nota" &&
                                                    //     n.materieId === m.materie
                                                    // ).length < 4 &&
                                                    // (
                                                    //   gradesElevi[e.id]?.note ||
                                                    //   []
                                                    // ).filter(
                                                    //   (n) =>
                                                    //     n.tip === "corigenta" &&
                                                    //     n.materieId === m.materie
                                                    // ).length === 0
                                                    //   ? "#eb9371"
                                                    //   : "unset",
                                                  }}
                                                >
                                                  <CatalogTabel.Cell>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column", // Elemente unul sub altul
                                                        justifyContent:
                                                          "center", // Centrare verticală
                                                        alignItems: "center", // Centrare orizontală
                                                        margin: "auto", // Centrare generală în container
                                                        textAlign: "center", // Text centrat
                                                      }}
                                                    >
                                                      {gradesElevi[
                                                        e.id
                                                      ]?.absente.absente_dupa_motivari
                                                        .filter(
                                                          (abs) =>
                                                            abs.materieId ===
                                                            m.materie
                                                        )
                                                        ?.map((nota) => {
                                                          let date = new Date(
                                                            nota.date
                                                          );
                                                          return nota.motivat ===
                                                            false ? (
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  nota?.delete ===
                                                                  "waiting"
                                                                    ? "grey"
                                                                    : "red",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                              onClick={() => {
                                                                setAllData(
                                                                  nota
                                                                );
                                                                setId(nota.id);
                                                                setMaterieId(
                                                                  nota.materieId
                                                                );
                                                                setTip(
                                                                  nota.tip
                                                                );
                                                                setDeleted(
                                                                  nota?.delete
                                                                );
                                                                setAuthor(
                                                                  nota.author ||
                                                                    ""
                                                                );
                                                                setComentariu(
                                                                  nota.comentariu
                                                                );
                                                                setData(
                                                                  new Date(
                                                                    nota.date
                                                                  )
                                                                );
                                                                setElevId2(
                                                                  e.id
                                                                );
                                                                setEntity(nota);
                                                                setOpen2(true);
                                                              }}
                                                            >
                                                              {formatDate(date)}
                                                            </p>
                                                          ) : (
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                                textAlign:
                                                                  "center",
                                                                border:
                                                                  nota?.scutire
                                                                    ?.tip ===
                                                                  "bilet"
                                                                    ? "2.5px solid #a3eb07"
                                                                    : "1px solid green",
                                                                textAlign:
                                                                  "center",

                                                                borderStyle:
                                                                  nota?.scutire
                                                                    ?.tip ===
                                                                  "bilet"
                                                                    ? "dotted"
                                                                    : "solid",

                                                                wordBreak:
                                                                  "keep-all",
                                                                borderRadius:
                                                                  "2px",
                                                                maxWidth:
                                                                  "40px",
                                                                color:
                                                                  nota?.delete ===
                                                                  "waiting"
                                                                    ? "grey"
                                                                    : "green",
                                                              }}
                                                              onClick={() => {
                                                                setAllData(
                                                                  nota
                                                                );
                                                                setMaterieId(
                                                                  nota.materieId
                                                                );
                                                                setTip(
                                                                  nota.tip
                                                                );
                                                                setDeleted(
                                                                  nota?.delete
                                                                );

                                                                setAuthor(
                                                                  nota.author ||
                                                                    ""
                                                                );
                                                                setId(nota.id);
                                                                setComentariu(
                                                                  nota.comentariu
                                                                );
                                                                setData(
                                                                  new Date(
                                                                    nota.date
                                                                  )
                                                                );
                                                                setElevId2(
                                                                  e.id
                                                                );
                                                                setOpen2(true);
                                                                setEntity(nota);
                                                              }}
                                                            >
                                                              {formatDate(date)}
                                                            </p>
                                                          );
                                                        })}
                                                    </div>
                                                  </CatalogTabel.Cell>

                                                  <CatalogTabel.Cell>
                                                    <div
                                                      style={{
                                                        display: "block",
                                                        gridTemplateColumns:
                                                          "auto",
                                                      }}
                                                    >
                                                      {(
                                                        gradesElevi[e.id]
                                                          ?.note || []
                                                      )
                                                        .filter(
                                                          (n) =>
                                                            n.tip === "nota" &&
                                                            n.materieId ===
                                                              m.materie
                                                        )
                                                        .map((nota) => (
                                                          <p
                                                            style={{
                                                              fontSize: "18px",
                                                              textAlign:
                                                                "center",
                                                              color:
                                                                nota?.delete ===
                                                                "waiting"
                                                                  ? "grey"
                                                                  : "#1c90ff",
                                                            }}
                                                            onClick={() => {
                                                              setAllData(nota);
                                                              setNota(
                                                                nota.nota
                                                              );
                                                              setMaterieId(
                                                                nota.materieId
                                                              );
                                                              setTip(nota.tip);
                                                              setDeleted(
                                                                nota?.delete
                                                              );
                                                              setAuthor(
                                                                nota.author ||
                                                                  ""
                                                              );
                                                              setComentariu(
                                                                nota.comentariu
                                                              );
                                                              setId(nota.id);
                                                              setData(
                                                                new Date(
                                                                  nota.date
                                                                )
                                                              );
                                                              setElevId2(e.id);
                                                              setOpen2(true);
                                                            }}
                                                          >
                                                            {nota.nota}
                                                          </p>
                                                        ))}
                                                    </div>
                                                    {(
                                                      gradesElevi[e.id]?.note ||
                                                      []
                                                    ).find(
                                                      (n) =>
                                                        n.materieId ===
                                                          m.materie &&
                                                        n.tip === "examen_final"
                                                    ) && (
                                                      <p
                                                        style={{
                                                          textAlign: "center",
                                                          color:
                                                            (
                                                              gradesElevi[e.id]
                                                                ?.note || []
                                                            ).find(
                                                              (n) =>
                                                                n.materieId ===
                                                                  m.materie &&
                                                                n.tip ===
                                                                  "examen_final"
                                                            )?.delete ===
                                                            "waiting"
                                                              ? "grey"
                                                              : "purple",
                                                          textAlign: "center",
                                                        }}
                                                        onClick={() => {
                                                          const nota = (
                                                            gradesElevi[e.id]
                                                              ?.note || []
                                                          ).find(
                                                            (n) =>
                                                              n.materieId ===
                                                                m.materie &&
                                                              n.tip ===
                                                                "examen_final"
                                                          );
                                                          setAllData(nota);
                                                          setNota(
                                                            nota.examen_final
                                                          );
                                                          setMaterieId(
                                                            nota.materieId
                                                          );
                                                          setTip(nota.tip);
                                                          setDeleted(
                                                            nota.delete
                                                          );
                                                          setAuthor(
                                                            nota.author || ""
                                                          );
                                                          setComentariu(
                                                            nota.comentariu
                                                          );
                                                          setId(nota.id);
                                                          setData(
                                                            new Date(nota.date)
                                                          );
                                                          setElevId2(e.id);
                                                          setOpen2(true);
                                                        }}
                                                      >
                                                        Ex.Final:
                                                        {
                                                          (
                                                            gradesElevi[e.id]
                                                              ?.note || []
                                                          ).find(
                                                            (n) =>
                                                              n.materieId ===
                                                                m.materie &&
                                                              n.tip ===
                                                                "examen_final"
                                                          ).examen_final
                                                        }
                                                      </p>
                                                    )}
                                                    {corigenta && (
                                                      <p
                                                        style={{
                                                          textAlign: "center",
                                                          fontSize: "10px",
                                                          color: "delete =",
                                                          paddingTop: "5px",
                                                          borderTop:
                                                            "1px solid black",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Media intiala:{" "}
                                                        {medie.medieInitiala}
                                                      </p>
                                                    )}
                                                    {corigenta && (
                                                      <p
                                                        style={{
                                                          fontSize: "10px",
                                                          textAlign: "center",
                                                          paddingTop: "5px",
                                                          borderTop:
                                                            "1px solid black",
                                                          color:
                                                            nota?.delete ===
                                                            "waiting"
                                                              ? "grey"
                                                              : "purple",
                                                        }}
                                                        onClick={() => {
                                                          setAllData(nota);
                                                          setNota(
                                                            corigenta?.corigenta
                                                          );
                                                          setMaterieId(
                                                            corigenta?.materieId
                                                          );
                                                          setTip(
                                                            corigenta?.tip
                                                          );
                                                          setDeleted(
                                                            nota.delete
                                                          );
                                                          setAuthor(
                                                            corigenta?.author ||
                                                              ""
                                                          );
                                                          setComentariu(
                                                            corigenta?.comentariu
                                                          );
                                                          setId(corigenta?.id);
                                                          setData(
                                                            new Date(
                                                              corigenta?.date
                                                            )
                                                          );
                                                          setElevId2(e.id);
                                                          setOpen2(true);
                                                        }}
                                                      >
                                                        {medie.noteInsuficiente
                                                          ? "Medie neîncheiat:"
                                                          : "Media corigenta:"}{" "}
                                                        {corigenta.corigenta}
                                                      </p>
                                                    )}
                                                    <Space>
                                                      {materii
                                                        .find(
                                                          (ma) =>
                                                            ma.id === m?.materie
                                                        )
                                                        ?.numeMaterie?.includes(
                                                          "Educație fizică"
                                                        ) &&
                                                        e?.scutitMedical
                                                          ?.length > 0 &&
                                                        e?.scutitMedical
                                                          ?.length > 0 &&
                                                        (e?.dataExpirareMedical
                                                          ? new Date() <=
                                                            new Date(
                                                              e.dataExpirareMedical
                                                            )
                                                          : true) &&
                                                        e.scutitMedical !==
                                                          "nu" && (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                              fontSize: "12px",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            -scutit medical
                                                            conform{" "}
                                                            {e.scutitMedical}-
                                                          </p>
                                                        )}
                                                      {materii.find(
                                                        (ma) =>
                                                          ma.id === m?.materie
                                                      )?.numeMaterie ===
                                                        "Religie" &&
                                                        e?.religie?.length >
                                                          0 &&
                                                        e?.religie !== "da" && (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                              fontSize: "12px",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            -retras religie
                                                            conform {e?.religie}
                                                            -
                                                          </p>
                                                        )}
                                                    </Space>
                                                  </CatalogTabel.Cell>
                                                </CatalogTabel.Row>
                                              </CatalogTabel.Body>
                                            </CatalogTabel>
                                          </CatalogTabel.Cell>
                                        );
                                      })}
                                    </CatalogTabel.Row>
                                    <CatalogTabel.Row>
                                      {group.map((m) => {
                                        let medie = calculeaza_medie_materie(
                                          gradesElevi[e.id]?.note,
                                          materii?.find(
                                            (n) => n.id == m.materie
                                          ),
                                          scutiri[e.id]
                                        );
                                        let { corigenta, inchis } = medie;
                                        return (
                                          <CatalogTabel.Cell
                                            style={{
                                              height: "20px",

                                              textAlign: "center",
                                              width: "120%",
                                            }}
                                          >
                                            Medie:
                                            {inchis ? (
                                              <>
                                                {edit !== inchis?.id ? (
                                                  <>
                                                    {formatMedia(
                                                      inchis.inchidere,
                                                      false
                                                    )}
                                                  </>
                                                ) : (
                                                  <div></div>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {corigenta ? (
                                                  <>
                                                    {" "}
                                                    {corigenta.corigenta +
                                                      "(" +
                                                      formatMedia(
                                                        corigenta.corigenta,
                                                        false
                                                      ) +
                                                      ")"}
                                                  </>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    {medie.medie +
                                                      "(" +
                                                      formatMedia(
                                                        medie.medie,
                                                        false
                                                      ) +
                                                      ")"}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </CatalogTabel.Cell>
                                        );
                                      })}
                                    </CatalogTabel.Row>
                                  </CatalogTabel.Body>
                                </CatalogTabel>
                              );
                            })}
                          </CatalogTabel.Cell>
                          <CatalogTabel.Cell>
                            <CatalogTabel>
                              <CatalogTabel.Header>
                                {e === elevi[0] && (
                                  <CatalogTabel.Row>
                                    <CatalogTabel.HeaderCell
                                      style={{ width: "50%" }}
                                    >
                                      Total
                                    </CatalogTabel.HeaderCell>
                                    <CatalogTabel.HeaderCell
                                      style={{ width: "50%" }}
                                    >
                                      Nemot
                                    </CatalogTabel.HeaderCell>
                                  </CatalogTabel.Row>
                                )}
                              </CatalogTabel.Header>
                              <CatalogTabel.Body
                                style={{
                                  height: e == elevi[0] ? "350px" : "300px",
                                }}
                              >
                                <CatalogTabel.Row
                                  style={{
                                    height: e == elevi[0] ? "300px" : "270px",
                                  }}
                                ></CatalogTabel.Row>

                                <CatalogTabel.Row style={{ height: "50px" }}>
                                  <CatalogTabel.Cell
                                    style={{
                                      textAlign: "center",
                                      width: "50%",
                                    }}
                                  >
                                    {
                                      gradesElevi?.[e?.id]?.absente
                                        ?.absente_dupa_motivari?.length
                                    }
                                  </CatalogTabel.Cell>
                                  <CatalogTabel.Cell
                                    style={{
                                      textAlign: "center",
                                      width: "50%",
                                    }}
                                  >
                                    {
                                      gradesElevi[e.id].absente
                                        .absente_nemotivate.length
                                    }
                                  </CatalogTabel.Cell>
                                </CatalogTabel.Row>
                              </CatalogTabel.Body>
                            </CatalogTabel>
                          </CatalogTabel.Cell>
                        </CatalogTabel.Row>
                      );
                    })}
                  </CatalogTabel.Body>
                </CatalogTabel>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Catalog;
