import React, { useState } from "react";

import { Button } from "antd";
import { Collapse } from "antd";
import GitInfo from "react-git-info/macro";
import MateriiSettings from "../../Components/Materii/MateriiSettings";
import ModalAni from "./ModalAni";
import Condica from "../../Pages/Profesori/Condica";
import { useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../../database/firebase";
import { useRef } from "react";
import ModalSettingsModule from "./ModalSettingsModule";
import { useReactToPrint } from "react-to-print";
const { Panel } = Collapse;

function Settings() {
  const [show, setShow] = useState(false);
  const dates = [
    new Date("2023-09-01"),
    new Date("2023-10-01"),
    new Date("2023-11-01"),
    new Date("2023-12-01"),
    new Date("2024-01-01"),
    new Date("2024-02-01"),
    new Date("2024-03-01"),
    new Date("2024-04-01"),
    new Date("2024-05-01"),
    new Date("2024-06-01"),
    new Date("2024-07-01"),
    new Date("2024-08-01"),
    new Date("2024-09-01"),
  ];
  const [showModule, setShowModule] = useState(false);
  const [open, setOpen] = useState(false);
  const profesori = useSelector((state) => state.profesori);
  const gitInfo = GitInfo();
  const [date, setDate] = useState(dates[0]);
  const [activeIndex, setActiveIndex] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const Print = () => {
    return (
      <div ref={componentRef}>
        {[...profesori]
          .sort((a, b) => {
            return (a?.numeDeFamilie + a.prenume)?.localeCompare(
              b?.numeDeFamilie + b.prenume,
              "ro"
            );
          })
          .map((p) => {
            return (
              <div style={{ textAlign: "center", pageBreakAfter: "always" }}>
                <br />
                <br />
                <h1>{p.numeDeFamilie + " " + p.prenume}</h1>
                <Condica profesorData={p} dateDefault={date} print={true} />
              </div>
            );
          })}
      </div>
    );
  };
  return (
    <div
      style={{
        display: "block",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {/* <div style={{ display: "none" }}>
        <Print />
      </div> */}
      <MateriiSettings
        setOpen={setShow}
        open={show}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
      />
      <ModalAni setIsModalOpen={setOpen} open={open} />

      <h2> Setări Platforma</h2>
      <br />
      <br />
      <Collapse
        styled
        style={{
          width: "80%",
          marginLeft: "10%",
          marginRight: "10px",
          paddingRight: "10px",
        }}
        onChange={(key) => {
          setActiveIndex(key);
        }}
      >
        <Panel key="1" header="Resurse">
          {" "}
          <div
            style={{
              display: "flex",
              flexDirection: "grow",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setShow(true);
              }}
            >
              Setări Materii
            </Button>
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              Setări Ani
            </Button>
            <br />
            <br />
            <br />
            <br />

            {/* <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
                width: "11.5vw",
              }}
              onClick={() => {
                setShowModule(true);
              }}
            >
              Setari Module
            </Button> */}
            <br />
          </div>
        </Panel>
      </Collapse>

      <Collapse
        styled
        style={{
          width: "80%",
          marginLeft: "10%",
          marginRight: "10px",
          paddingRight: "10px",
        }}
      ></Collapse>
      <Collapse
        styled
        style={{
          width: "80%",
          marginLeft: "10%",
          marginRight: "10px",
          paddingRight: "10px",
        }}
      >
        <Panel key="2" header="Despre">
          <div style={{ fontWeight: "bold" }}>
            Versiune : 2.0
            <br />
            Ultima actualizare platforma:{" "}
            {new Date(gitInfo?.commit?.date).toLocaleDateString("ro-RO")}
            <br />
            Mesaj de actualizare: {gitInfo.commit.message}
            <br />
            <br />
            PFA Dobricean Ioan Dorian
            <br />
            0747934436 <br />
            dobriceanionut@dobriceansoftware.com
            <br />
            dobriceanionut1408@gmail.com
          </div>
        </Panel>
      </Collapse>

      <Collapse
        styled
        style={{
          width: "80%",
          marginLeft: "10%",
          marginRight: "10px",
          paddingRight: "10px",
        }}
      >
        {/* <Panel key="2" header="Condica">
          <div style={{ fontWeight: "bold" }}>
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[0]);
                if (date.toDateString() == dates[0].toDateString())
                  handlePrint();
              }}
            >
              Export Condica Septembrie
            </Button>
            <br />
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[1]);
                if (date.toDateString() == dates[1].toDateString())
                  handlePrint();
              }}
            >
              Export Condica Octombrie
            </Button>
            <br />
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[2]);
                if (date.toDateString() == dates[2].toDateString())
                  handlePrint();
              }}
            >
              Export Condica Noiembrie
            </Button>
            <br />
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[3]);
                if (date.toDateString() == dates[3].toDateString())
                  handlePrint();
              }}
            >
              Export Condica Decembrie
            </Button>
            <br />
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[4]);
                if (date.toDateString() == dates[4].toDateString())
                  handlePrint();
              }}
            >
              Export Condica Ianuarie
            </Button>
            <br />
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[5]);
                if (date.toDateString() == dates[5].toDateString())
                  handlePrint();
              }}
            >
              Export Condica Februarie
            </Button>
            <br />
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[6]);
                if (date.toDateString() == dates[6].toDateString())
                  handlePrint();
              }}
            >
              Export Condica Martie
            </Button>
            <br />
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[7]);
                if (date.toDateString() == dates[7].toDateString())
                  handlePrint();
              }}
            >
              Export Condica Aprile
            </Button>
            <br />
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[8]);
                if (date.toDateString() == dates[8].toDateString())
                  handlePrint();
              }}
            >
              Export Condica Mai
            </Button>

            <br />
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[9]);

                if (date.toDateString() === dates[9].toDateString())
                  handlePrint();
              }}
            >
              Export Condica Iunie
            </Button>
             <br />
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[10]);

                if (date.toDateString() === dates[10].toDateString())
                  handlePrint();
              }}
            >
              Export Condica Iulie
            </Button>
             <br />
            <br />
            <Button
              style={{
                backgroundColor: "yellow",
                color: "black",
              }}
              onClick={() => {
                setDate(dates[11]);

                if (date.toDateString() === dates[11].toDateString())
                  handlePrint();
              }}
            >
              Export Condica August
            </Button>
          </div>
        </Panel> */}
      </Collapse>
      <br />
      <br />
      <Button
        style={{ backgroundColor: "red", color: "white", fontWeight: "bold" }}
        onClick={() => {
          signOut(auth);
        }}
      >
        Iesi din cont
      </Button>
      <br />
    </div>
  );
}

export default Settings;
